import { QnA } from "@models/Qna";
import ServiceMappers from "./ServiceMappers";
import Utils from "./utils";

const FaqMappers = {
  mapQna: (payload: any): QnA => {
    return {
      id: payload?.id || "",
      slug: payload?.slug || "",
      question: payload?.question || "",
      meta: payload?.meta || null,
      answer: Utils.mapPosh(payload?.answer),
      services: ServiceMappers.mapServices(payload?.services) || [],
      showList: payload?.showList || false,
      featured: payload?.featured || false,
    } as QnA;
  },
  mapQnas: (payload: any): QnA[] => {
    if (payload && Array.isArray(payload) && payload.length) {
      const qnas: QnA[] = payload.map((elem) => FaqMappers.mapQna(elem));
      return qnas.filter((qna) => !!qna.slug);
    }

    return [];
  },
};

export default FaqMappers;
