import { LocationContext } from "@app/contexts/LocationContext";
import { COMPANY_PHONE_NUMBER, SOCIAL_FACEBOOK, SOCIAL_LINKEDIN } from "@app/utilities/constants";
import Link from "next/link";
import React, { useContext } from "react";

const Footer: React.FC = () => {
  const currentDate = new Date(),
    { locations } = useContext(LocationContext),
    currentYear = currentDate.getFullYear();

  return (
    <footer className="footer-comp" data-testid="footer-comp">
      <div className="container">
        <div className="wrapper">
          <nav className="nav-directory">
            <div className="row">
              <div className="col-12 col-sm-4">
                <ul className="list-group list-group-flush">
                  <li className="list-group-item mb-1">
                    <strong>Locations</strong>
                  </li>
                  {locations.map((geoloc, idx) => {
                    return (
                      <li className="list-group-item" key={idx}>
                        <Link href={`/locations/${geoloc.slug}`}>
                          <a>{geoloc.name}</a>
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
              <div className="col-12 col-sm-4">
                {!!COMPANY_PHONE_NUMBER && (
                  <ul className="list-group list-group-flush">
                    <li className="list-group-item mb-1">
                      <strong>Contacts</strong>
                    </li>
                    <li className="list-group-item">
                      <address>
                        5910 N Central Expy <br />
                        Suite 1820 <br />
                        Dallas, TX 75206 <br />
                        <a role="button" title="Call our office" aria-label="Call our office" href={`tel:${COMPANY_PHONE_NUMBER}`} rel="noopener noreferrer">
                          {COMPANY_PHONE_NUMBER}
                        </a>
                      </address>
                    </li>
                  </ul>
                )}
              </div>
              <div className="col-12 col-sm-4"></div>
            </div>
          </nav>
          <hr />
          <section className="navbar brand-navbar">
            <ul className="navbar-nav">
              <li className="nav-item">
                <span>&copy; {currentYear} Heritage Counseling, P.A.</span>
              </li>
            </ul>
            <ul className="nav-social navbar-nav navbar-expand ml-md-auto">
              <li className="nav-item">
                <a href={SOCIAL_FACEBOOK} className="btn" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
                  <i className="fab fa-facebook-square"></i>
                </a>
              </li>
              <li className="nav-item">
                <a href={SOCIAL_LINKEDIN} className="btn" target="_blank" rel="noopener noreferrer" aria-label="LinkedIn">
                  <i className="fab fa-linkedin"></i>
                </a>
              </li>
            </ul>
          </section>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
